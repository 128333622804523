import React from 'react';
import {connect} from 'react-redux';

import * as api from '../api';
import { history } from '../history';
import {clearCampaign, setCampaign} from '../redux/actions/campaigns';
import {withMessages} from '../hoc/with-messages';
import {Route, Switch} from 'react-router-dom';
import NewCampaign from '../components/campaigns/NewCampaign';
import {setTypes} from '../redux/actions/types';
import ListCampaigns from '../components/campaigns/ListCampaigns';
import UpdateCampaign from '../components/campaigns/UpdateCampaign';

const LIST_CAMPAIGN_MESSAGE = 'list-campaign-message';
const NEW_CAMPAIGN_MESSAGE = 'new-campaign-message';
const EDIT_CAMPAIGN_MESSAGE = 'edit-campaign-message';

class CampaignContainer extends React.Component {
	fetchCampaigns = (page, maxDisplay) => {
		return api.fetchPagCampaigns(page, maxDisplay).catch(err => {
			this.props.setMessage(LIST_CAMPAIGN_MESSAGE, 'Campagnes konden niet worden opgehaald', err.response.data.message, {
				color: 'danger',
			});
		});
	}

	searchCampaigns = (page, maxDisplay, searchTerm) => {
		return api.fetchPagCampaignsSearch(page, maxDisplay, searchTerm).catch(err => {
			this.props.setMessage(LIST_CAMPAIGN_MESSAGE, 'Campagnes konden niet worden opgehaald', err.response.data.message, {
				color: 'danger',
			});
		});
	}

	fetchCampaign = id => {
		return api.fetchCampaign(id).then(campaign => {
			this.props.dispatch(setCampaign(campaign));
		}).catch(err => {
			this.props.setMessage(EDIT_CAMPAIGN_MESSAGE, 'Campagne kon niet worden opgehaald', err.response.data.message, {
				color: 'danger',
			});
		});
	}

	clearCampaign = () => this.props.dispatch(clearCampaign());

	fetchTypes = () => {
		api.fetchTypes().then(types => {
			this.props.dispatch(setTypes(types));
		}).catch(err => {
			this.props.setMessage(LIST_CAMPAIGN_MESSAGE, 'Types konden niet worden opgehaald', err.response.data.message, {
				color: 'danger',
			});
		});
	}

	createNewCampaign = data => {
		try {
			data.addresses = parseInt(data.addresses, 10);
		} catch (e) {
			// Fail silently
		}

		api.createNewCampaign(data).then(({ id }) => {
			this.props.setMessage(LIST_CAMPAIGN_MESSAGE, 'Success', 'Campagne met success aangemaakt', {
				color: 'success',
			});
			history.push(`/campaigns/${id}/mailings`);
		}).catch(err => {
			this.props.setMessage(NEW_CAMPAIGN_MESSAGE, 'Fout tijdens aanmaken van campagne', err.response.data.message, {
				color: 'danger',
			});
		});
	}

	updateCampaign = (id, data) => {
		try {
			data.addresses = parseInt(data.addresses, 10);
		} catch (e) {
			// Fail silently
		}

		api.updateCampaign(id, data).then(() => {
			this.props.setMessage(LIST_CAMPAIGN_MESSAGE, 'Success', 'Campagne met success opgeslagen', {
				color: 'success',
			});
			history.push('/campaigns');
		}).catch(err => {
			this.props.setMessage(EDIT_CAMPAIGN_MESSAGE, 'Fout tijdens opslaan van campagne', err.response.data.message, {
				color: 'danger',
			});
		});
	}

	deleteCampaign = id => {
		api.deleteCampaign(id).then(() => {
			this.props.setMessage(LIST_CAMPAIGN_MESSAGE, 'Success', 'Campagne wordt verwijderd', {
				color: 'success',
			});
		}).catch(err => {
			this.props.setMessage(LIST_CAMPAIGN_MESSAGE, 'Fout tijdens verwijderen van campagne', err.response.data.message, {
				color: 'danger',
			});
		});
	}

	render() {
		return <Switch>
			<Route path="/campaigns/:id/edit" render={props => {
				return <UpdateCampaign
					onSubmit={this.updateCampaign}
					lastMessage={this.props.messages[EDIT_CAMPAIGN_MESSAGE]}
					types={this.props.types}
					fetchTypes={this.fetchTypes}
					fetchCampaign={() => this.fetchCampaign(props.match.params.id)}
					clearCampaign={this.clearCampaign}
					campaign={this.props.selectedCampaign}
				/>;
			}} />
			<Route path="/campaigns/new" render={() => {
				return <NewCampaign
					onSubmit={this.createNewCampaign}
					lastMessage={this.props.messages[NEW_CAMPAIGN_MESSAGE]}
					types={this.props.types}
					fetchTypes={this.fetchTypes}
				/>;
			}} />
			<Route path="/campaigns" render={() => {
				return <ListCampaigns
					campaigns={this.props.campaigns}
					fetchCampaigns={this.fetchCampaigns}
					searchCampaigns={this.searchCampaigns}
					lastMessage={this.props.messages[LIST_CAMPAIGN_MESSAGE]}
					deleteCampaign={this.deleteCampaign}
				/>;
			}} />
		</Switch>;
	}
}

const mapStateToProps = store => {
	const {campaigns, selectedCampaign} = store.campaigns;
	const {types} = store.types;

	return {
		campaigns,
		types,
		selectedCampaign,
	};
};

export default connect(mapStateToProps)(withMessages(CampaignContainer));
